<template>
  <main>
    <div class="pb">
      <Button icon="md-return-left" shape="circle" @click="$router.back()">返回</Button>
    </div>

    <Form ref="form" :model="form" :rules="validate" label-position="top">
      <Divider orientation="left">基本信息</Divider>
      <Row>
        <Col :span="9" :offset="3">
          <FormItem label="题库分类/科目">
            <Cascader :data="cascaderOptions" v-model="cascaderValue" @on-change="onChangeCascader" v-width="300"/>
          </FormItem>
          <FormItem label="试卷标题" prop="name" class="w100">
            <Input :maxlength="60" show-word-limit v-model="form.name"/>
          </FormItem>
          <FormItem label="权重排序">
            <InputNumber
              :min="-32768"
              :max="32767"
              class="input-number-long"
              v-model="form.weight"
            />
            <p class="text-sm text-grey">从大到小排序</p>
          </FormItem>

          <FormItem label="是否可见">
            <i-switch v-model="form.visible" size="large" :true-value="1" :false-value="0">
              <span slot="open">是</span>
              <span slot="close">否</span>
            </i-switch>
          </FormItem>
        </Col>
      </Row>

      <Divider/>
      <Row>
        <Col :span="9" :offset="3">
          <FormItem>
            <Button type="primary" icon="md-add-circle" @click="submit" v-if="!id">确认添加</Button>
            <Button type="success" icon="md-filing" @click="submit" v-else>保存修改</Button>
          </FormItem>
        </Col>
      </Row>
    </Form>
  </main>
</template>

<script>
export default {
  data() {
    return {
      id: this.$route.params.id,
      form: {
        name: "",
        question_category_id: 0,
        question_subject_id: 0,
        weight:0,
        visible:1
      },
      validate: {
        name: [{ required: true, message: "请输入试卷标题", trigger: "blur" }],
      },
      cascaderOptions: [],
      cascaderValue:[],
    };
  },
  created() {
    this.loadCategorys();
  },
  methods: {
    submit() {
      if (!this.form.question_subject_id)
        return this.$Message.error("请选择题库科目");

      this.$refs.form.validate(valid => {
        if (valid) {
          const url =
            "/exam/" + (this.id ? "update/id/" + this.id : "create");
          this.$http.post(url, this.form).then(res => {
            this.$router.back();
          });
        }
      });
    },
    loadData() {
      this.$http.get("/exam/detail/id/" + this.id).then(res => {
        this.form = res;
        this.cascaderValue=[]
        this.cascaderValue.push(res.subject.category.id)
        this.cascaderValue.push(res.subject.id)
        
      });
    },
    loadCategorys() {
      this.$http.get("/questionCategory/list").then(res => {
        this.id && this.loadData();

        let data = [];
        res.data.forEach(cagegory => {
          let tempCagegory = {
            value: cagegory.id,
            label: cagegory.name,
            children: []
          };

          cagegory.subjects.forEach(subject => {
            tempCagegory.children.push({
              value: subject.id,
              label: subject.name
            });
          });
          data.push(tempCagegory);
        });
        this.cascaderOptions = data;
      });
    },
    onChangeCascader(e) {
      this.form.question_category_id = e[0];
      if (e.length > 1) {
        this.form.question_subject_id = e[1];
      }
    }
  }
};
</script>